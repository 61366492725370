import { default as _91_46_46_46slug_93am8FzMvhm4Meta } from "/buddy/roastar-combined/nuxt/pages/[...slug].vue?macro=true";
import { default as _91slug_93qwM02oUtKtMeta } from "/buddy/roastar-combined/nuxt/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93W1fCyPe2O2Meta } from "/buddy/roastar-combined/nuxt/pages/blog/category/[slug].vue?macro=true";
import { default as _91slug_93M7sHNeoetcMeta } from "/buddy/roastar-combined/nuxt/pages/careers/[slug].vue?macro=true";
import { default as indexDVCC3pb4LMMeta } from "/buddy/roastar-combined/nuxt/pages/cart/checkout/index.vue?macro=true";
import { default as _91id_93ZiwyMc68WcMeta } from "/buddy/roastar-combined/nuxt/pages/cart/checkout/success/[id].vue?macro=true";
import { default as indexIaAXBPKcf6Meta } from "/buddy/roastar-combined/nuxt/pages/cart/index.vue?macro=true";
import { default as components_45libraryhM4Vhq2WmNMeta } from "/buddy/roastar-combined/nuxt/pages/components-library.vue?macro=true";
import { default as design_45and_45pricepn1DoGQDzVMeta } from "/buddy/roastar-combined/nuxt/pages/design-and-price.vue?macro=true";
import { default as _91_46_46_46slug_93phJFjrqRblMeta } from "/buddy/roastar-combined/nuxt/pages/design-lab/[...slug].vue?macro=true";
import { default as DesignLabDisabledhWEuzyHrqFMeta } from "/buddy/roastar-combined/nuxt/pages/design-lab/includes/DesignLabDisabled.vue?macro=true";
import { default as indexfFBflMTcUGMeta } from "/buddy/roastar-combined/nuxt/pages/design-lab/index.vue?macro=true";
import { default as design_45libraryzopUw7F8nJMeta } from "/buddy/roastar-combined/nuxt/pages/design-library.vue?macro=true";
import { default as get_45quotesdtjxhBpjEMeta } from "/buddy/roastar-combined/nuxt/pages/get-quote.vue?macro=true";
import { default as loginXMsYl7Ai1OMeta } from "/buddy/roastar-combined/nuxt/pages/login.vue?macro=true";
import { default as forgotL9JJpkDebjMeta } from "/buddy/roastar-combined/nuxt/pages/password/forgot.vue?macro=true";
import { default as _91token_93uS3mGmTc7tMeta } from "/buddy/roastar-combined/nuxt/pages/password/reset/[token].vue?macro=true";
import { default as _91_91sku_93_93ommrZbW8HaMeta } from "/buddy/roastar-combined/nuxt/pages/products/[slug]/[[sku]].vue?macro=true";
import { default as _91id_93l777ypLHVPMeta } from "/buddy/roastar-combined/nuxt/pages/quotes/[id].vue?macro=true";
import { default as registerfy6sTml4zcMeta } from "/buddy/roastar-combined/nuxt/pages/register.vue?macro=true";
import { default as _91slug_93wCuBD4K57SMeta } from "/buddy/roastar-combined/nuxt/pages/resources/designers/[slug].vue?macro=true";
import { default as faqsSnLMqMvRU2Meta } from "/buddy/roastar-combined/nuxt/pages/resources/faqs.vue?macro=true";
import { default as indexr5Zk0vYhvAMeta } from "/buddy/roastar-combined/nuxt/pages/roastar-design-lab/index.vue?macro=true";
import { default as reviewi3tP2ZtaS5Meta } from "/buddy/roastar-combined/nuxt/pages/roastar-design-lab/review.vue?macro=true";
import { default as sitemapa6ZDNm974eMeta } from "/buddy/roastar-combined/nuxt/pages/sitemap.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93am8FzMvhm4Meta?.name ?? "slug",
    path: _91_46_46_46slug_93am8FzMvhm4Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93am8FzMvhm4Meta || {},
    alias: _91_46_46_46slug_93am8FzMvhm4Meta?.alias || [],
    redirect: _91_46_46_46slug_93am8FzMvhm4Meta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qwM02oUtKtMeta?.name ?? "blog-slug",
    path: _91slug_93qwM02oUtKtMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93qwM02oUtKtMeta || {},
    alias: _91slug_93qwM02oUtKtMeta?.alias || [],
    redirect: _91slug_93qwM02oUtKtMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W1fCyPe2O2Meta?.name ?? "blog-category-slug",
    path: _91slug_93W1fCyPe2O2Meta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93W1fCyPe2O2Meta || {},
    alias: _91slug_93W1fCyPe2O2Meta?.alias || [],
    redirect: _91slug_93W1fCyPe2O2Meta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7sHNeoetcMeta?.name ?? "careers-slug",
    path: _91slug_93M7sHNeoetcMeta?.path ?? "/careers/:slug()",
    meta: _91slug_93M7sHNeoetcMeta || {},
    alias: _91slug_93M7sHNeoetcMeta?.alias || [],
    redirect: _91slug_93M7sHNeoetcMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/careers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexDVCC3pb4LMMeta?.name ?? "cart-checkout",
    path: indexDVCC3pb4LMMeta?.path ?? "/cart/checkout",
    meta: indexDVCC3pb4LMMeta || {},
    alias: indexDVCC3pb4LMMeta?.alias || [],
    redirect: indexDVCC3pb4LMMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/cart/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZiwyMc68WcMeta?.name ?? "cart-checkout-success-id",
    path: _91id_93ZiwyMc68WcMeta?.path ?? "/cart/checkout/success/:id()",
    meta: _91id_93ZiwyMc68WcMeta || {},
    alias: _91id_93ZiwyMc68WcMeta?.alias || [],
    redirect: _91id_93ZiwyMc68WcMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/cart/checkout/success/[id].vue").then(m => m.default || m)
  },
  {
    name: indexIaAXBPKcf6Meta?.name ?? "cart",
    path: indexIaAXBPKcf6Meta?.path ?? "/cart",
    meta: indexIaAXBPKcf6Meta || {},
    alias: indexIaAXBPKcf6Meta?.alias || [],
    redirect: indexIaAXBPKcf6Meta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: components_45libraryhM4Vhq2WmNMeta?.name ?? "components-library",
    path: components_45libraryhM4Vhq2WmNMeta?.path ?? "/components-library",
    meta: components_45libraryhM4Vhq2WmNMeta || {},
    alias: components_45libraryhM4Vhq2WmNMeta?.alias || [],
    redirect: components_45libraryhM4Vhq2WmNMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/components-library.vue").then(m => m.default || m)
  },
  {
    name: design_45and_45pricepn1DoGQDzVMeta?.name ?? "design-and-price",
    path: design_45and_45pricepn1DoGQDzVMeta?.path ?? "/design-and-price",
    meta: design_45and_45pricepn1DoGQDzVMeta || {},
    alias: design_45and_45pricepn1DoGQDzVMeta?.alias || [],
    redirect: design_45and_45pricepn1DoGQDzVMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/design-and-price.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93phJFjrqRblMeta?.name ?? "design-lab-slug",
    path: _91_46_46_46slug_93phJFjrqRblMeta?.path ?? "/design-lab/:slug(.*)*",
    meta: _91_46_46_46slug_93phJFjrqRblMeta || {},
    alias: _91_46_46_46slug_93phJFjrqRblMeta?.alias || [],
    redirect: _91_46_46_46slug_93phJFjrqRblMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/design-lab/[...slug].vue").then(m => m.default || m)
  },
  {
    name: DesignLabDisabledhWEuzyHrqFMeta?.name ?? "design-lab-includes-DesignLabDisabled",
    path: DesignLabDisabledhWEuzyHrqFMeta?.path ?? "/design-lab/includes/DesignLabDisabled",
    meta: DesignLabDisabledhWEuzyHrqFMeta || {},
    alias: DesignLabDisabledhWEuzyHrqFMeta?.alias || [],
    redirect: DesignLabDisabledhWEuzyHrqFMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/design-lab/includes/DesignLabDisabled.vue").then(m => m.default || m)
  },
  {
    name: indexfFBflMTcUGMeta?.name ?? "design-lab",
    path: indexfFBflMTcUGMeta?.path ?? "/design-lab",
    meta: indexfFBflMTcUGMeta || {},
    alias: indexfFBflMTcUGMeta?.alias || [],
    redirect: indexfFBflMTcUGMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/design-lab/index.vue").then(m => m.default || m)
  },
  {
    name: design_45libraryzopUw7F8nJMeta?.name ?? "design-library",
    path: design_45libraryzopUw7F8nJMeta?.path ?? "/design-library",
    meta: design_45libraryzopUw7F8nJMeta || {},
    alias: design_45libraryzopUw7F8nJMeta?.alias || [],
    redirect: design_45libraryzopUw7F8nJMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/design-library.vue").then(m => m.default || m)
  },
  {
    name: get_45quotesdtjxhBpjEMeta?.name ?? "get-quote",
    path: get_45quotesdtjxhBpjEMeta?.path ?? "/get-quote",
    meta: get_45quotesdtjxhBpjEMeta || {},
    alias: get_45quotesdtjxhBpjEMeta?.alias || [],
    redirect: get_45quotesdtjxhBpjEMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/get-quote.vue").then(m => m.default || m)
  },
  {
    name: loginXMsYl7Ai1OMeta?.name ?? "login",
    path: loginXMsYl7Ai1OMeta?.path ?? "/login",
    meta: loginXMsYl7Ai1OMeta || {},
    alias: loginXMsYl7Ai1OMeta?.alias || [],
    redirect: loginXMsYl7Ai1OMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: forgotL9JJpkDebjMeta?.name ?? "password-forgot",
    path: forgotL9JJpkDebjMeta?.path ?? "/password/forgot",
    meta: forgotL9JJpkDebjMeta || {},
    alias: forgotL9JJpkDebjMeta?.alias || [],
    redirect: forgotL9JJpkDebjMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: _91token_93uS3mGmTc7tMeta?.name ?? "password-reset-token",
    path: _91token_93uS3mGmTc7tMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93uS3mGmTc7tMeta || {},
    alias: _91token_93uS3mGmTc7tMeta?.alias || [],
    redirect: _91token_93uS3mGmTc7tMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: _91_91sku_93_93ommrZbW8HaMeta?.name ?? "products-slug-sku",
    path: _91_91sku_93_93ommrZbW8HaMeta?.path ?? "/products/:slug()/:sku?",
    meta: _91_91sku_93_93ommrZbW8HaMeta || {},
    alias: _91_91sku_93_93ommrZbW8HaMeta?.alias || [],
    redirect: _91_91sku_93_93ommrZbW8HaMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/products/[slug]/[[sku]].vue").then(m => m.default || m)
  },
  {
    name: _91id_93l777ypLHVPMeta?.name ?? "quotes-id",
    path: _91id_93l777ypLHVPMeta?.path ?? "/quotes/:id()",
    meta: _91id_93l777ypLHVPMeta || {},
    alias: _91id_93l777ypLHVPMeta?.alias || [],
    redirect: _91id_93l777ypLHVPMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/quotes/[id].vue").then(m => m.default || m)
  },
  {
    name: registerfy6sTml4zcMeta?.name ?? "register",
    path: registerfy6sTml4zcMeta?.path ?? "/register",
    meta: registerfy6sTml4zcMeta || {},
    alias: registerfy6sTml4zcMeta?.alias || [],
    redirect: registerfy6sTml4zcMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/register.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wCuBD4K57SMeta?.name ?? "resources-designers-slug",
    path: _91slug_93wCuBD4K57SMeta?.path ?? "/resources/designers/:slug()",
    meta: _91slug_93wCuBD4K57SMeta || {},
    alias: _91slug_93wCuBD4K57SMeta?.alias || [],
    redirect: _91slug_93wCuBD4K57SMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/resources/designers/[slug].vue").then(m => m.default || m)
  },
  {
    name: faqsSnLMqMvRU2Meta?.name ?? "resources-faqs",
    path: faqsSnLMqMvRU2Meta?.path ?? "/resources/faqs",
    meta: faqsSnLMqMvRU2Meta || {},
    alias: faqsSnLMqMvRU2Meta?.alias || [],
    redirect: faqsSnLMqMvRU2Meta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/resources/faqs.vue").then(m => m.default || m)
  },
  {
    name: indexr5Zk0vYhvAMeta?.name ?? "roastar-design-lab",
    path: indexr5Zk0vYhvAMeta?.path ?? "/roastar-design-lab",
    meta: indexr5Zk0vYhvAMeta || {},
    alias: indexr5Zk0vYhvAMeta?.alias || [],
    redirect: indexr5Zk0vYhvAMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/roastar-design-lab/index.vue").then(m => m.default || m)
  },
  {
    name: reviewi3tP2ZtaS5Meta?.name ?? "roastar-design-lab-review",
    path: reviewi3tP2ZtaS5Meta?.path ?? "/roastar-design-lab/review",
    meta: reviewi3tP2ZtaS5Meta || {},
    alias: reviewi3tP2ZtaS5Meta?.alias || [],
    redirect: reviewi3tP2ZtaS5Meta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/roastar-design-lab/review.vue").then(m => m.default || m)
  },
  {
    name: sitemapa6ZDNm974eMeta?.name ?? "sitemap",
    path: sitemapa6ZDNm974eMeta?.path ?? "/sitemap",
    meta: sitemapa6ZDNm974eMeta || {},
    alias: sitemapa6ZDNm974eMeta?.alias || [],
    redirect: sitemapa6ZDNm974eMeta?.redirect || undefined,
    component: () => import("/buddy/roastar-combined/nuxt/pages/sitemap.vue").then(m => m.default || m)
  }
]