<template>
    <NuxtLoadingIndicator color="#832C41" />

    <NuxtLayout :name="layoutName">
        <NuxtPage />
    </NuxtLayout>

    <AddToCartFlyout />
    <AlertModal />
</template>

<script setup lang="ts">
import {
    headlessPageRoutes,
    ppcPageRoutes,
    headlessPPCRoutes,
} from '~/data/layouts/pageRoutes';
import { useSegmentCode } from '~/composables/useSegment';
import AddToCartFlyout from '~/components/cart/AddToCartFlyout.vue';

const route = useRoute();
const { liveChatEnabled } = useRuntimeConfig().public;

const scripts = [];
if (liveChatEnabled) {
    scripts.push({ src: '/js/zopim.js', async: true });
}
scripts.push({
    src: '//cdn-4.convertexperiments.com/v1/js/100414036-100415423.js?environment=production',
});
useHead({
    script: scripts,
});

const layoutName = computed(() => {
    if (ppcPageRoutes.includes(route.path)) {
        return 'ppc';
    }

    if (
        headlessPageRoutes.includes(route.path) ||
        /^\/quotes\/\d+$/.test(route.path)
    ) {
        return 'headless';
    }

    if (headlessPPCRoutes.includes(route.path)) {
        return 'headless-ppc';
    }

    return undefined;
});

onMounted(() => {
    const { user } = useAuth();
    const segmentCode = useSegmentCode(user?.value?.id);

    useHead(() => ({
        bodyAttrs: {
            class: 'nuxt-loaded',
        },
        script: segmentCode,
    }));
});
</script>
