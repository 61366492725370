export const ppcPageRoutes = [
    '/coffee/roastar-difference',
    '/coffee/roastar-trade-show',
    '/occasions',
    '/holiday-packaging-designs',
    '/seasonal',
    '/wedding',
];

export const headlessPageRoutes = ['/product-finder', '/get-quote'];

export const headlessPPCRoutes = ['/design-and-price'];
