import { User } from '~/types/User';
import { sendDataLayerEvent } from '~/composables/useDataLayer';
import cartIconQuantity from '~/state/CartIconQuantity';
import siteNotification from '~/state/SiteNotification';

const state = reactive<{
    loading: boolean;
    user: User | null | undefined;
}>({
    loading: true,
    user: undefined,
});

const userRequest = ref<Promise<void> | null>(null);

const apiRequest = async (
    method: 'GET' | 'POST',
    endpoint: string,
    data: { [key: string]: any } | FormData = {},
) => {
    const csrfToken = await getCsrfToken();

    return await $fetch(`api/${endpoint}`, {
        method,
        baseURL: useRuntimeConfig().public.portalUrl,
        credentials: 'include',
        retry: false,
        headers: {
            Accept: 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        },
        ['POST' === method ? 'body' : 'query']: data,
    });
};

const fetchUser = async () => {
    if (!userRequest.value) {
        userRequest.value = $fetch('api/user', {
            baseURL: useRuntimeConfig().public.portalUrl,
            credentials: 'include',
            headers: {
                Accept: 'application/json',
            },
        })
            .then((responseData: any) => {
                state.user = responseData.user;
                cartIconQuantity.value = responseData.cartItemsCount;
                siteNotification.value = responseData.siteNotification;
            })
            .finally(() => {
                state.loading = false;
            });
    }

    return userRequest.value;
};

const getCsrfToken = async (): Promise<string> => {
    const csrfToken = useCookie('XSRF-TOKEN').value;
    if (csrfToken) {
        return csrfToken;
    }

    await $fetch('sanctum/csrf-cookie', {
        baseURL: useRuntimeConfig().public.portalUrl,
        credentials: 'include',
    });

    return useCookie('XSRF-TOKEN').value as string;
};

const login = async (
    email: string,
    password: string,
    remember: boolean,
    companyInviteToken?: string,
) => {
    return await apiRequest('POST', 'login', {
        email,
        password,
        remember,
        token_invite: companyInviteToken,
    }).then((responseData: any) => {
        state.user = responseData.data.user;
        cartIconQuantity.value = responseData.data.cartItemsCount;
        sendDataLayerEvent('login');
        return Promise.resolve(responseData);
    });
};

const requestPasswordReset = async (email: string) => {
    return await apiRequest('POST', 'send-password-reset-email', {
        email,
    }).then((responseData: any) => {
        return Promise.resolve(responseData);
    });
};

const resetPassword = async (
    email: string,
    password: string,
    confirmPassword: string,
    token?: string,
) => {
    return await apiRequest('POST', 'reset-password', {
        email,
        password,
        password_confirmation: confirmPassword,
        token,
    }).then((responseData: any) => {
        return Promise.resolve(responseData);
    });
};

const logout = async () => {
    state.user = null;
    cartIconQuantity.value = 0;

    return apiRequest('POST', 'logout');
};

const register = async (
    firstName: string,
    lastName: string,
    company: string,
    zipCode: string,
    email: string,
    password: string,
    remember: boolean,
    emailOptIn: boolean,
    recaptchaToken?: string,
    companyInviteToken?: string,
) => {
    return await apiRequest('POST', 'register', {
        first_name: firstName,
        last_name: lastName,
        company,
        zip_code: zipCode,
        email,
        password,
        remember,
        email_optin: emailOptIn,
        recaptchaToken,
        token_invite: companyInviteToken,
    }).then((responseData: any) => {
        state.user = responseData.data.user;
        cartIconQuantity.value = responseData.data.user.cartItemsCount;
        sendDataLayerEvent('create_account');
        return Promise.resolve(responseData);
    });
};

export default function () {
    return {
        ...toRefs(state),
        apiRequest,
        fetchUser,
        getCsrfToken,
        login,
        logout,
        register,
        requestPasswordReset,
        resetPassword,
    };
}
