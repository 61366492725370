const sendDataLayerPageView = async () => {
    await checkIfDataLayerReady();
    const payload = {
        event: 'customer_identified',
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const sendDataLayerEvent = async (event: string) => {
    await checkIfDataLayerReady();
    const payload = {
        event: event,
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const sendDataLayerEcommerceEvent = async (
    event: string,
    funnelAttribution: any,
    ecommercePayload: any,
) => {
    await checkIfDataLayerReady();

    ecommercePayload.item_list_name = normalizeEcommerceItemAttribute(
        ecommercePayload.item_list_name,
    );

    for (let i = 0; i < ecommercePayload?.items?.length; i++) {
        ecommercePayload.items[i].item_name = ecommercePayload.items[
            i
        ].item_name
            ?.replace(/(?:^|\s|["'([{])+\S/g, (match: any) =>
                match.toUpperCase(),
            )
            .replace(/ X /g, ' x ');
        ecommercePayload.items[i].item_category =
            normalizeEcommerceItemAttribute(
                ecommercePayload.items[i].item_category,
            );
        ecommercePayload.items[i].item_category2 =
            normalizeEcommerceItemAttribute(
                ecommercePayload.items[i].item_category2,
            );
        ecommercePayload.items[i].item_category3 =
            normalizeEcommerceItemAttribute(
                ecommercePayload.items[i].item_category3,
            );
        ecommercePayload.items[i].color = normalizeEcommerceItemAttribute(
            ecommercePayload.items[i].color,
        );
        ecommercePayload.items[i].finish = normalizeEcommerceItemAttribute(
            ecommercePayload.items[i].finish,
        );
        ecommercePayload.items[i].material = normalizeEcommerceItemAttribute(
            ecommercePayload.items[i].material,
        );
    }

    const payload = {
        event: event,
        funnel_attribution: funnelAttribution,
        ecommerce: ecommercePayload,
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(payload);
};

const sendDataLayerFormEvent = async (
    event: string,
    formName: string,
    formId: string,
) => {
    await checkIfDataLayerReady();
    const payload = {
        event: event,
        form_name: formName,
        form_id: formId,
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const sendDataLayerQuizEvent = async (
    event: string,
    quizStep: string,
    quizQuestion: string,
    quizAnswer: string,
    quizQuestionValue: string,
    quizAnswerValue: string,
    quizResultMain: string,
    quizResultAlternate: string,
    quizResultAlternate2: string,
) => {
    if (event == 'quiz_started') {
        await checkIfDataLayerReady();
        const payload = {
            event: event,
            event_group: 'Quiz',
            ...getDataLayerUserPayload(),
        };

        window.dataLayer.push(payload);
        return;
    } else if (event == 'quiz_complete') {
        await checkIfDataLayerReady();
        const payload = {
            event: event,
            quiz_result_main: quizResultMain,
            quiz_result_alternate: quizResultAlternate,
            quiz_result_alternate2: quizResultAlternate2,
            event_group: 'Quiz',
            ...getDataLayerUserPayload(),
        };

        window.dataLayer.push(payload);
        return;
    }

    await checkIfDataLayerReady();
    const payload = {
        event: event,
        quiz_step: quizStep,
        quiz_question: quizQuestion,
        quiz_answer: quizAnswer,
        quiz_question_value: quizQuestionValue,
        quiz_answer_value: quizAnswerValue,
        event_group: 'Quiz',
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const sendDataLayerDesignLabEvent = async (
    event: string,
    productType: string,
    theme: string,
    size: string,
    material: string,
) => {
    await checkIfDataLayerReady();
    const payload = {
        event: event,
        product_type: productType,
        theme: theme,
        size: size,
        material: material,
        event_group: 'Design Lab',
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const sendDataLayerCustomProjectEvent = async (
    event: string,
    projectId: string,
    selectedDesigner: string,
    productType: string,
    size: string,
    material: string,
    additionalOptions: string,
) => {
    await checkIfDataLayerReady();
    const payload = {
        event: event,
        project_id: projectId,
        selected_designer: selectedDesigner,
        product_type: productType,
        size: size,
        material: material,
        additional_options: additionalOptions,
        event_group: 'Custom Project',
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const sendDataLayerQuoteEvent = async (
    event: string,
    quoteId: any,
    quotedPrice: any,
    productName: string,
    productQuantity: any,
    productContents: string,
    productSize: string,
) => {
    await checkIfDataLayerReady();
    const payload = {
        event: event,
        quote_id: quoteId,
        quoted_price: quotedPrice,
        product_name: productName,
        product_quantity: productQuantity,
        product_contents: productContents,
        product_size: productSize,
        event_group: 'Quote',
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const sendDataLayerDesignerEvent = async (
    event: string,
    designerName: string,
) => {
    await checkIfDataLayerReady();
    const payload = {
        event: event,
        designer_name: designerName,
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const sendDataLayerFilterEvent = async (
    event: string,
    filterFor: string,
    filterCategory: string,
    filterSelection: string,
) => {
    await checkIfDataLayerReady();
    const payload = {
        event: event,
        filter_for: filterFor,
        filter_category: filterCategory,
        filter_selection: filterSelection,
        event_group: 'Filter',
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const sendDataLayerFaqEvent = async (event: string, faqQuestion: string) => {
    await checkIfDataLayerReady();
    const payload = {
        event: event,
        faq_question: faqQuestion,
        ...getDataLayerUserPayload(),
    };

    window.dataLayer.push(payload);
};

const getSelectedThemeNameForCanvas = (canvas: any) => {
    if (canvas) {
        for (let i = 0; i < canvas?.objects?.length; i++) {
            if (canvas.objects[i].name == 'theme') {
                return canvas.objects[i].themeName ?? 'No Theme Selected';
            }
        }
    }
    return 'No Theme Selected';
};

const normalizeEcommerceItemAttribute = (attribute: any) => {
    if (attribute && typeof attribute == 'string') {
        return attribute
            .toString()
            .replace(/\-/g, ' ')
            .replace(/\_/g, ' ')
            .replace(/(?:^|\s|["'([{])+\S/g, (match: any) =>
                match.toUpperCase(),
            )
            .replace(/ X /g, ' x ')
            .replace(/  +/g, ' ');
    }

    return attribute;
};

const getDataLayerUserPayload = () => {
    const { user } = useAuth();
    return {
        user_id: user?.value?.id,
        name: user?.value?.name,
        email: user?.value?.email,
        company: user?.value?.company?.name,
    };
};

const checkIfDataLayerReady = () => {
    return new Promise((resolve) => {
        setInterval(() => {
            if (typeof window !== 'undefined') {
                if (window?.dataLayer) {
                    resolve(true);
                }
            }
        }, 100);
    });
};

export {
    sendDataLayerPageView,
    sendDataLayerEvent,
    sendDataLayerEcommerceEvent,
    sendDataLayerFormEvent,
    sendDataLayerQuizEvent,
    sendDataLayerDesignLabEvent,
    sendDataLayerCustomProjectEvent,
    sendDataLayerQuoteEvent,
    sendDataLayerDesignerEvent,
    sendDataLayerFilterEvent,
    sendDataLayerFaqEvent,
    getSelectedThemeNameForCanvas,
};
